import React from 'react'
import { Link } from 'gatsby'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>
    <Link to={`/blog/${article.slug}`}>
      <h2 className={styles.previewTitle}>{article.title}</h2>
    </Link>

    <div
      className={styles.text}
      dangerouslySetInnerHTML={{
        __html: article.body.childMarkdownRemark.html,
      }}
    />
    <div className={styles.date}>
      <small>{article.publishDate}</small>
    </div>
  </div>
)
